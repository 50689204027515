<template>
    <div class="text-center" >
        <v-dialog
            v-model="dialog"
            :width="this.$vuetify.breakpoint.name!='xs'?1500:310"
            persistent
            @click:outside="close()"
            
        >
            <v-card>
                <v-card-title >
                    <!-- <v-btn
                        v-if="this.$vuetify.breakpoint.name=='xs'"
                        color="secondary"
                        text
                        @click="close()"
                    >
                        {{addToCartLabel[languageId]}}
                    </v-btn> 
                    -->
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        @click="close()"
                        icon
                    >
                        <v-icon >mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text v-if="display!=undefined">
                    <v-row class="mb-4">
                        <v-col cols="12" md="6" lg="6" xl="6" align-self="center" v-if="this.$vuetify.breakpoint.name=='xs' || this.$vuetify.breakpoint.name=='sm'" >
                            <div style=" display:flex; flex-direction: column; align-items:center; justify-content:center; width:100%;">
                                <div style=" display:flex; flex-direction: column; align-items:center; justify-content:space-between; width:100%;">
                                    <span class="colorDarkBrown lusciousLifestylesScriptFont" style="font-size:67px; padding:0px; margin-bottom:15px; margin-top:-35px;"  v-if="!isComingSoon && isNew">{{newLabel[languageId]}}</span>
                                    <h2 v-if="display.title!=undefined" class="colorYellow poppinsboldFont" style="font-size:29px; text-align:left;">{{display.title.split("|")[languageId].replace(/\s/g," ")}}</h2>
                                    <h3 v-if="display.price!=undefined" class="mt-2 mb-2 colorDarkBrown poppinsboldFont"  style="font-size:22px;">$ {{(getPrice(display)* form.quantity).toLocaleString()}}</h3>
                                </div>
                                <div class="poppinsboldFont" style=" display:flex; flex-direction: row; align-items:center;  width:100%; color:#FF5C35; font-size:24px;" v-if="getOutOfStock()">
                                    {{outOfStockLabel[languageId]}}
                                </div>
                                <div v-if="isComingSoon" class="mt-5" style=" display:flex; flex-direction: column; align-items:flex-start; justify-content:space-between; width:100%;">
                                    <span class="colorDarkBrown lusciousLifestylesScriptFont mb-8" style="font-size:67px; padding:0px;" >{{comingSoonLabel[languageId]}}</span>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6" lg="6" xl="6" align-self="center">
                            <v-img
                                :src="require(`../../assets/${image}`)"
                                position="center"
                                contain
                                class="rounded-lg"
                                :aspect-ratio="aspectRatio"
                                :max-height="maxHeight"
                                :width="imageWidth"
                                style="margin:auto;"
                                :alt="history.includes('|')?history.split('|')[languageId]:history"
                            >
                            </v-img>
                        </v-col>
                        <v-col cols="12" md="6" lg="6" xl="6" v-if="display!=undefined" align-self="center">
                            <div
                                v-if="!(this.$vuetify.breakpoint.name=='xs' || this.$vuetify.breakpoint.name=='sm')" 
                                style=" display:flex; flex-direction: column; align-items:center; justify-content:center; width:100%;"
                            >
                                <div class="mt-5" style=" display:flex; flex-direction: column; align-items:center; justify-content:space-between; width:100%;">
                                    <span class="colorDarkBrown lusciousLifestylesScriptFont" style="font-size:67px; padding:0px; margin-bottom:15px; margin-top:-25px;"  v-if="!isComingSoon && isNew">{{newLabel[languageId]}}</span>
                                    <h2 v-if="display.title!=undefined" class="colorYellow poppinsboldFont" style="font-size:29px; text-align:left;">{{display.title.split("|")[languageId].replace(/\s/g," ")}}</h2>
                                    <h3 v-if="display.price!=undefined" class="mt-2 mb-2 colorDarkBrown poppinsboldFont"  style="font-size:22px;">$ {{(getPrice(display)* form.quantity).toLocaleString()}}</h3>
                                </div>
                                <div class="poppinsboldFont" style=" display:flex; flex-direction: row; align-items:center;  width:100%; color:#FF5C35; font-size:24px;" v-if="getOutOfStock()">
                                    {{outOfStockLabel[languageId]}}
                                </div>
                                <div v-if="isComingSoon" class="mt-5" style=" display:flex; flex-direction: column; align-items:flex-start; justify-content:space-between; width:100%;">
                                    <span class="colorDarkBrown lusciousLifestylesScriptFont" style="font-size:67px; padding:0px;" >{{comingSoonLabel[languageId]}}</span>
                                </div>
                            </div>
                            <v-form ref="form" lazy-validation >
                                <v-row no-gutters v-if="!isComingSoon" align-content="center">
                                    <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pr-4" >
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-select 
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    v-model="form.size"
                                                    :items="artPieceSizes(display.sizes)"
                                                    item-text="name"
                                                    item-value="id"
                                                    :error-messages="sizeError?sizeErrorMessage[languageId]:[]"
                                                    @click="sizeError=false"
                                                    style="max-width:230px; margin-left:auto;"
                                                    color="#848484"
                                                    item-color="#848484"
                                                    class="poppinsLightFont"
                                                >
                                                    <template v-slot:label>
                                                        <p class="colorYellow poppinsRegularFont uppercase" style="font-size:20px;">{{sizeLabel[languageId]}} <v-icon small color="colorDarkGrey">mdi-information-outline</v-icon></p>
                                                    </template>
                                                </v-select>
                                            </template>
                                            <span>{{sizeHint[languageId]}}</span>
                                        </v-tooltip>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pl-4" >
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-select
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    v-model="form.printedMedium"
                                                    :items="artPiecesPrintedMediums(display.printedMediums)"
                                                    item-text="name"
                                                    item-value="id"
                                                    :error-messages="printedMediumError?printedMediumErrorMessage[languageId]:[]"
                                                    @click="printedMediumError=false"
                                                    style="max-width:230px; margin-right:auto;"
                                                    color="#848484"
                                                    item-color="#848484"
                                                    class="poppinsLightFont"
                                                >
                                                    <template v-slot:label>
                                                        <p class="colorYellow poppinsRegularFont uppercase" style="font-size:20px;">{{printedMediumLabel[languageId]}} <v-icon small color="colorDarkGrey">mdi-information-outline</v-icon></p>
                                                    </template>
                                                </v-select>
                                            </template>
                                            <span>{{printedMediumHint[languageId]}}</span>
                                        </v-tooltip>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pr-4" >
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-select
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    v-model="form.frame"
                                                    :items="artPiecesFrames(display.frames)"
                                                    item-text="name"
                                                    item-value="id"
                                                    :error-messages="frameError?frameErrorMessage[languageId]:[]"
                                                    @click="frameError=false"
                                                    style="max-width:230px; margin-left:auto;"
                                                    color="#848484"
                                                    item-color="#848484"
                                                    class="poppinsLightFont"
                                                >
                                                    <template v-slot:label>
                                                        <p class="colorYellow poppinsRegularFont uppercase" style="font-size:20px;">{{frameLabel[languageId]}} <v-icon small color="colorDarkGrey">mdi-information-outline</v-icon></p>
                                                    </template>
                                                </v-select>
                                            </template>
                                            <span>{{frameHint[languageId]}}</span>
                                        </v-tooltip>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pl-4">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-select
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    v-model="form.liner"
                                                    :items="artPiecesLiners(display.liners)"
                                                    item-text="name"
                                                    item-value="id"
                                                    :error-messages="linerError?linerErrorMessage[languageId]:[]"
                                                    @click="linerError=false"
                                                    style="max-width:230px; margin-right:auto;"
                                                    color="#848484"
                                                    item-color="#848484"
                                                    class="poppinsLightFont"
                                                >
                                                    <template v-slot:label>
                                                        <p class="colorYellow poppinsRegularFont uppercase" style="font-size:20px;">{{linerLabel[languageId]}} <v-icon small color="colorDarkGrey">mdi-information-outline</v-icon></p>
                                                    </template>
                                                </v-select>
                                            </template>
                                            <span>{{linerHint[languageId]}}</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters v-if="!getOutOfStock()">
                                    <v-col cols="12" sm="6" md="6" lg="6" xl="6"  class="px-4">
                                        <v-text-field
                                            v-if="!isComingSoon"
                                            v-model="form.quantity"
                                            type="number"
                                            dense
                                            min=1 
                                            :max="getMaxProductionBySize()"
                                            class="form-control"
                                            @keydown="positiveQuantityValidator($event)"
                                            style="max-width:100px;"
                                            :style="widthSize>=640?'margin-left:auto;':''"
                                        >
                                            <template v-slot:label>
                                                <p class="colorYellow poppinsRegularFont uppercase" style="font-size:20px;">{{amountLabel[languageId]}}</p>
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="pl-4">
                                        <v-btn v-if="!isComingSoon" color="secondary" @click="addToCart()" >
                                            {{addToCartLabel[languageId]}}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-col>
                    </v-row>
                </v-card-text>
                <!-- <v-divider></v-divider> -->
                <!-- <v-card-actions>
                    <a :href="`art-pieces/${title.split('|')[languageId]}`">{{moreDetailsLabel[languageId]}}</a>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="secondary"
                        text
                        @click="addToCart()"
                        v-if="display!=undefined && !getOutOfStock()"
                    >
                        {{addToCartLabel[languageId]}}
                    </v-btn>
                </v-card-actions> -->
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
	export default {
		name: 'ArtModal',
        props: ['image', 'url', 'title', 'dialog', 'orientation', 'history'],
        computed: {
            ...mapState("shoppingCart", 
                [ 
                    "artPieces", "languageId", "metricSystemId",  "soldUnits", "amountLabel", "comingSoonLabel", "newLabel",
                    "size", "printedMedium", "frame", "liner", "shoppingCart", "moreDetailsLabel", "addToCartLabel", 
                    "priceLabel","sizeLabel","printedMediumLabel","frameLabel","linerLabel","inStockLabel","outOfStockLabel","sizeErrorMessage","printedMediumErrorMessage","frameErrorMessage","linerErrorMessage","sizeHint","printedMediumHint","frameHint","linerHint","addToCartLabel"
                ]),
            isNew(){
                return this.display.isNew;
            },
            isComingSoon(){
                return this.display.price == null
            },
			isSmallScreen(){
				var screenType = "Desktop";
				if(this.displaySizeClass == 'mobile-size')
					screenType = "Mobile";
				if(this.displaySizeClass == 'tablet-size')
					screenType = "Tablet";
				return screenType
			},
			displaySizeClass(){
				var displayClass = "desktop-size";
				var windowWidth = this.widthSize;
				if(windowWidth <= 640)
					displayClass = "mobile-size";
				if(windowWidth >= 1024 && windowWidth <= 1440)
					displayClass = "tablet-size";
				return displayClass
			},
			widthSize(){
				var windowWidth = window.innerWidth;
				if(windowWidth < 320)
					windowWidth = 320
				if(windowWidth > 640 && windowWidth < 1024)
					windowWidth = 640
				if(windowWidth > 1440 && windowWidth < 1920)
					windowWidth = 1440
				if(windowWidth > 1920)
					windowWidth = 1920
				return windowWidth
			},
            maxHeight(){
                let height = window.innerHeight;
                if(height<=900)
                    return 550;
                return 700;
            },
        },
        async mounted(){
            var display = this.artPieces.find(x=>x.title.includes(this.title))
            if(display != undefined){
                this.display = display;
                var inShoppingCart = this.shoppingCart.find(x=>x.title.includes(display.title))
                if(inShoppingCart != undefined){
                    this.form = inShoppingCart;
                }
                else{
                    this.form = {
                        title:display.title,
                        size:parseInt(display.sizes.split("|")[0]),
                        printedMedium:parseInt(display.printedMediums.split("|")[0]),
                        frame:parseInt(display.frames.split("|")[0]),
                        liner:parseInt(display.liners.split("|")[0]),
                        quantity:1,
                        unitPrice:this.getPrice(display),
                    }
                }
            }
            this.preview();
        },
		data: () => ({
			isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			),
            form:{
                title:"",
                size:null,
                printedMedium:null,
                frame:null,
                liner:null,
                quantity:1,
                unitPrice:null,
            },
            display:undefined,
            sizeError:false,
            printedMediumError:false,
            frameError:false,
            linerError:false,
            quantityError:false,
            aspectRatio:2/3,
            imageWidth:'',
		}),
        methods:{
            ...mapActions("shoppingCart", ["addItemToCart",]),
            positiveQuantityValidator(event){
                return (event.key === '-' || event.key === '+' || event.key === '.' || event.key === ',' || event.key === 'e'  || event.key === 'E')? event.preventDefault() : null
            },
            getSizeIndex(){
                var sizes = this.artPieceSizes(this.display.sizes)
                return sizes.indexOf(sizes.find(x=>x.id == this.form.size))
            },
            getMaxProductionBySize(){
                var soldUnitsNumber = 0;
                var soldUnits = this.soldUnits.find(x=>x.title == this.form.title)
                if(soldUnits != undefined){
                    var soldIndex = soldUnits.sizes.indexOf(this.form.size.toString());
                    if(soldIndex != -1){
                        soldUnitsNumber = soldUnits.soldUnits[soldIndex];
                    }
                }
                return parseInt(this.display.productionQuantityBySize.split("|")[this.getSizeIndex()] - soldUnitsNumber)
            },
            getOutOfStock(){
                return this.display.outOfStock.split('|')[this.getSizeIndex()] == "true"
            },
            artPieceSizes(sizes){
                return this.size.filter(x=>sizes.split("|").includes(x.id.toString())).map(x=>{return {id:x.id,name:x.name.split("|")[this.metricSystemId]}})
            },
            artPiecesPrintedMediums(printedMediums){
                return this.printedMedium.filter(x=>printedMediums.split("|").includes(x.id.toString())).map(x=>{return {id:x.id,name:x.name.split("|")[this.languageId]}})
            },
            artPiecesFrames(frames){
                return this.frame.filter(x=>frames.split("|").includes(x.id.toString())).map(x=>{return {id:x.id,name:x.name.split("|")[this.languageId]}})
            },
            artPiecesLiners(liners){
                return this.liner.filter(x=>liners.split("|").includes(x.id.toString())).map(x=>{return {id:x.id,name:x.name.split("|")[this.languageId]}})
            },
            getPrice(artPiece){
                var size = this.form.size!=null? this.size.find(x=>x.id == this.form.size) : null
                var printedMediumPrice =  this.form.printedMedium!=null? this.printedMedium.find(x=>x.id == this.form.printedMedium).price : 0
                var framePrice =  this.form.frame!=null? this.frame.find(x=>x.id == this.form.frame).price : 0
                var linerPrice =  this.form.liner!=null? this.liner.find(x=>x.id == this.form.liner).price : 0
                var widthXHeight = size!=null? size.width * size.height: 0
                var sizePrice = size!=null? size.price : 0
                return Math.round(artPiece.price + sizePrice*widthXHeight + printedMediumPrice*widthXHeight + framePrice*widthXHeight + linerPrice*widthXHeight);
            },
            close(){
                this.$emit('closed', false)
            },
            addToCart(){
                this.form.unitPrice = this.getPrice(this.display);
                this.validations();
                if(this.error) return;
                this.addItemToCart({property:"shoppingCart",with:this.form})
                this.$emit('added', this.form.title)
            },
            validations(){
                this.error=false;
                if(this.form.size==null){
                    this.sizeError=true;
                    this.error=true;
                }
                else{
                    this.sizeError=false;
                }
                if(this.form.printedMedium==null){
                    this.printedMediumError=true;
                    this.error=true;
                }
                else{
                    this.printedMediumError=false;
                }
                if(this.form.frame==null){
                    this.frameError=true;
                    this.error=true;
                }
                else{
                    this.frameError=false;
                }
                if(this.form.liner==null){
                    this.linerError=true;
                    this.error=true;
                }
                else{
                    this.linerError=false;
                }
                if(this.form.quantity<=0){
                    this.form.quantity = 1;
                    this.quantityError=true;
                    this.error=true;
                }
                else{
                    this.quantityError=false;
                }
                if(this.form.quantity > this.getMaxProductionBySize()){
                    this.form.quantity = this.getMaxProductionBySize();
                    this.quantityError=true;
                    this.error=true;
                }
                else{
                    this.quantityError=false;
                }
            },
            
            async preview(){
                let image = new Image();
                image.src = require(`../../assets/${this.image}`);
                const imageWidth = image.width;
                const imageHeight = image.height;
                const aspectRatio = imageWidth/imageHeight;
                this.aspectRatio = aspectRatio;
                if(imageHeight >= this.maxHeight){
                    this.imageWidth = this.maxHeight*aspectRatio;
                }
                else{
                    this.imageWidth = imageWidth
                }
            },
        }
	}
</script>

<style scoped>
a{
    text-decoration:none;
}
a:hover{
    text-decoration:underline;
}
</style>
