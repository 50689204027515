<template>
	<v-container :style="isSmallScreen!='Mobile'?'position:sticky; top:10px;':''">
        <v-row v-if="isSmallScreen != 'Mobile'" style="overflow-y:scroll;" :style="`height:${maxHeight}px;`" class="pb-2" align-content="start">
            <v-col cols="12" class="poppinsSemiboldFont">
                {{filterLabel[languageId]}}
                <v-btn v-if="anyFilter" class="ml-4" color="error" icon x-small @click="cleanAllFilters" text max-width="25">
                    <v-icon >mdi-close</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="12" v-if="false">
                <v-list dense style="width:100%; max-width:250px;">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="poppinsSemiboldFont" style="font-size:14px;">
                                {{collectionLabel[languageId]}}
                                <v-btn v-if="collectionFilter!=0" class="ml-4" color="error" icon x-small @click="cleanCollection" text max-width="25">
                                    <v-icon >mdi-close</v-icon>
                                </v-btn>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item v-for="(collection,index) in collectionNames" :key="index" link @click="filterCollection(collection.id)">
                        <v-list-item-content>
                            <v-list-item-title class="poppinsRegularFont" >
                                <a @click="filterCollection(collection.id)" :class="{active:(collection.id==collectionFilter)}">{{collection.name.split("|")[languageId]}}</a>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-col>
            <v-col cols="12">
                <v-expansion-panels
                    v-model="panel"
                    multiple
                    style="width:100%; max-width:250px;"
                    flat
                >
                    <filterListData 
                        :filterLabel="categoriesLabel"
                        :filterList="categoriesNames"
                        :filterData="categoriesFilter"
                        @changeFilter="(val)=>{filterData(val,'categories')}"
                        @cleanFilter="()=>{cleanFilter('categories')}"
                        />
                    <filterListData 
                        :filterLabel="colorsLabel"
                        :filterList="colorsNames"
                        :filterData="colorsFilter"
                        @changeFilter="(val)=>{filterData(val,'colors')}"
                        @cleanFilter="()=>{cleanFilter('colors')}"
                        />
                    <filterListData 
                        :filterLabel="stylesLabel"
                        :filterList="stylesNames"
                        :filterData="stylesFilter"
                        @changeFilter="(val)=>{filterData(val,'styles')}"
                        @cleanFilter="()=>{cleanFilter('styles')}"
                        />
                    <filterListData 
                        :filterLabel="roomsLabel"
                        :filterList="roomsNames"
                        :filterData="roomsFilter"
                        @changeFilter="(val)=>{filterData(val,'rooms')}"
                        @cleanFilter="()=>{cleanFilter('rooms')}"
                        />
                    <filterListData 
                        :filterLabel="shapesLabel"
                        :filterList="shapesNames"
                        :filterData="shapesFilter"
                        @changeFilter="(val)=>{filterData(val,'shapes')}"
                        @cleanFilter="()=>{cleanFilter('shapes')}"
                        />

                    <v-expansion-panel>
                        <v-divider></v-divider>
                        <v-expansion-panel-header  class="poppinsSemiboldFont" style="font-size:14px;">
                            {{priceLabel[languageId]}}
                            <v-btn v-if="priceFilter.length>0" class="ml-4" color="error" icon x-small @click="cleanPrice" text max-width="25">
                                <v-icon >mdi-close</v-icon>
                            </v-btn>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-list dense >
                                <v-list-item link>
                                    <v-list-item-content>
                                        <v-list-item-title class="poppinsRegularFont">
                                            <v-range-slider
                                                v-model="priceRange"
                                                :min="prices.min"
                                                :max="prices.max"
                                                step="50"
                                                @change="filterPrice"
                                            >
                                            </v-range-slider>
                                            <v-row no-gutters style="margin-top:-20px;">
                                                <v-col cols="5" style="text-align:left"><p>$ {{priceRange[0]?priceRange[0].toLocaleString():''}}</p></v-col>
                                                <v-col cols="2"></v-col>
                                                <v-col cols="5" style="text-align:right"><p>$ {{priceRange[1]?priceRange[1].toLocaleString():''}}</p></v-col>
                                            </v-row>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <filterListData 
                        :filterLabel="collectionLabel"
                        :filterList="collectionNames"
                        :filterData="collectionFilter"
                        @changeFilter="(val)=>{filterData(val,'collection')}"
                        @cleanFilter="()=>{cleanFilter('collection')}"
                        /> 
                    <!-- <v-expansion-panel>
                        <v-divider></v-divider>
                        <v-expansion-panel-header  class="poppinsSemiboldFont" style="font-size:14px;">
                            {{sizeLabel[languageId]}}
                            <v-btn v-if="sizeFilter!=0" class="ml-4" color="error" icon x-small @click="cleanSize" text max-width="25">
                                <v-icon >mdi-close</v-icon>
                            </v-btn>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-list dense >
                                <v-list-item v-for="(tamano,index) in usedSizes" :key="index" link @click="filterSize(tamano.id)">
                                    <v-list-item-content>
                                        <v-list-item-title class="poppinsRegularFont" >
                                            <a @click="filterSize(tamano.id)" :class="{active:(tamano.id==sizeFilter)}">{{tamano.name.split("|")[languageId]}}</a>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-expansion-panel-content>
                    </v-expansion-panel> -->
                </v-expansion-panels>
            </v-col>
        </v-row>
        <v-row v-if="isSmallScreen == 'Mobile'">
            <v-dialog v-model="dialog" scrollable max-width="300px" persistent >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark v-bind="attrs" v-on="on" class="poppinsRegularFont">
                        {{filterLabel[languageId]}}
                    </v-btn>
                </template>
                <v-hover >
                    <v-card>
                        <v-card-title class="poppinsSemiboldFont">
                            <h3 style="font-size:20px;">{{filterLabel[languageId]}}</h3>
                            <v-spacer></v-spacer>
                            <v-btn color="error" @click="close()" icon >
                                <v-icon >mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text style="text-align:center;">
                            <v-row>
                                <v-expansion-panels hover multiple accordion flat tile v-model="panel">
                                    <!-- <v-expansion-panel>
                                        <v-expansion-panel-header class="poppinsSemiboldFont" style="font-size:14px;">
                                            {{collectionLabel[languageId]}}
                                            <v-btn class="ml-4" color="error" icon x-small @click="cleanCollection" text max-width="25" v-if="collectionFilter!=0">
                                                <v-icon >mdi-close</v-icon>
                                            </v-btn>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content v-for="(collection,index) in collectionNames" :key="index" class="text-left v-expansion-panel-content poppinsRegularFont" style="font-size:13px;">
                                            <a @click="filterCollection(collection.id)" :class="{active:(collection.id==collectionFilter)}">{{collection.name.split("|")[languageId]}}</a>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel> -->
                                    <filterListData 
                                        :filterLabel="categoriesLabel"
                                        :filterList="categoriesNames"
                                        :filterData="categoriesFilter"
                                        @changeFilter="(val)=>{filterData(val,'categories')}"
                                        @cleanFilter="()=>{cleanFilter('categories')}"
                                        />
                                    <filterListData 
                                        :filterLabel="colorsLabel"
                                        :filterList="colorsNames"
                                        :filterData="colorsFilter"
                                        @changeFilter="(val)=>{filterData(val,'colors')}"
                                        @cleanFilter="()=>{cleanFilter('colors')}"
                                        />
                                    <filterListData 
                                        :filterLabel="stylesLabel"
                                        :filterList="stylesNames"
                                        :filterData="stylesFilter"
                                        @changeFilter="(val)=>{filterData(val,'styles')}"
                                        @cleanFilter="()=>{cleanFilter('styles')}"
                                        />
                                    <filterListData 
                                        :filterLabel="roomsLabel"
                                        :filterList="roomsNames"
                                        :filterData="roomsFilter"
                                        @changeFilter="(val)=>{filterData(val,'rooms')}"
                                        @cleanFilter="()=>{cleanFilter('rooms')}"
                                        />
                                    <!-- <filterListData 
                                        :filterLabel="themesLabel"
                                        :filterList="themesNames"
                                        :filterData="themesFilter"
                                        @changeFilter="(val)=>{filterData(val,'themes')}"
                                        @cleanFilter="()=>{cleanFilter('themes')}"
                                        /> -->
                                    <filterListData 
                                        :filterLabel="shapesLabel"
                                        :filterList="shapesNames"
                                        :filterData="shapesFilter"
                                        @changeFilter="(val)=>{filterData(val,'shapes')}"
                                        @cleanFilter="()=>{cleanFilter('shapes')}"
                                        />
                                    <v-expansion-panel>
                                        <v-divider></v-divider>
                                        <v-expansion-panel-header class="poppinsSemiboldFont" style="font-size:14px;">
                                            {{priceLabel[languageId]}}
                                            <v-btn class="ml-4" color="error" icon x-small @click="cleanPrice" text max-width="25" v-if="priceFilter.length>0">
                                                <v-icon >mdi-close</v-icon>
                                            </v-btn>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-range-slider v-model="priceRange" :min="prices.min" :max="prices.max" step="50" @change="filterPrice" ></v-range-slider>
                                            <v-row no-gutters style="margin-top:-20px; font-size:13px;" class="poppinsRegularFont">
                                                <v-col cols="5" style="text-align:left"><p>${{priceRange[0]?priceRange[0].toLocaleString():''}}</p></v-col>
                                                <v-col cols="2"></v-col>
                                                <v-col cols="5" style="text-align:right"><p>${{priceRange[1]?priceRange[1].toLocaleString():''}}</p></v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <!-- <v-expansion-panel>
                                        <v-divider></v-divider>
                                        <v-expansion-panel-header class="poppinsSemiboldFont" style="font-size:14px;">
                                            {{sizeLabel[languageId]}}
                                            <v-btn class="ml-4" color="error" icon x-small @click="cleanSize" text max-width="25" v-if="sizeFilter!=0">
                                                <v-icon >mdi-close</v-icon>
                                            </v-btn>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content v-for="(tamano,index) in usedSizes" :key="index" class="text-left v-expansion-panel-content poppinsRegularFont" style="font-size:13px;">
                                            <a @click="filterSize(tamano.id)" :class="{active:(tamano.id==sizeFilter)}">{{tamano.name.split("|")[languageId]}}</a>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel> -->
                                </v-expansion-panels>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-dialog>
        </v-row>
	</v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import filterListData from './FilterListData'
	export default {
		name: 'FilterByComponent',
		components: { filterListData},
        props: [/*'collectionNames',*/ 'prices'],
        computed: {
            ...mapState("shoppingCart", [
                "collectionNames",
                "collectionFilter", "priceFilter", "orderByLabel", "languageId", "collectionLabel", "priceLabel", "filterLabel", 
                "categoriesLabel","colorsLabel","stylesLabel","roomsLabel","themesLabel","shapesLabel",
                "categoriesNames","colorsNames","stylesNames","roomsNames","themesNames","shapesNames",
                "categoriesFilter","colorsFilter","stylesFilter","roomsFilter","themesFilter","shapesFilter",
                "sizeLabel", "sizeFilter", "size",
                "artPieces"
                ]),
            isSmallScreen(){
				var screenType = "Desktop";
				if(this.displaySizeClass == 'mobile-size')
					screenType = "Mobile";
				if(this.displaySizeClass == 'tablet-size')
					screenType = "Tablet";
				return screenType
			},
			displaySizeClass(){
				var displayClass = "desktop-size";
				var windowWidth = this.widthSize;
				if(windowWidth <= 640)
					displayClass = "mobile-size";
				if(windowWidth >= 1024 && windowWidth <= 1440)
					displayClass = "tablet-size";
				return displayClass
			},
			widthSize(){
				var windowWidth = window.innerWidth;
				if(windowWidth < 320)
					windowWidth = 320
				if(windowWidth > 640 && windowWidth < 1024)
					windowWidth = 640
				if(windowWidth > 1440 && windowWidth < 1920)
					windowWidth = 1440
				if(windowWidth > 1920)
					windowWidth = 1920
				return windowWidth
			},
            maxHeight(){
                return window.innerHeight - 20;
            },
            usedSizes(){
                let usedSizesInArtPieces = this.artPieces.map(x=>x.sizes.split("|"));
                let sizesIds = [];
                usedSizesInArtPieces.forEach(element => {
                    element.forEach(size=>{
                        sizesIds.push(size)
                    })
                });
                let uniqueSizes = [... new Set(sizesIds)];
                let sizes = []
                this.size.forEach(size=>{
                    if(uniqueSizes.includes(size.id.toString())) sizes.push(size);
                })
                return sizes
            },
            anyFilter(){
                return this.priceFilter.length>0 ||
                this.categoriesFilter != 0 ||
                this.colorsFilter != 0 ||
                this.stylesFilter != 0 ||
                this.roomsFilter != 0 ||
                this.themesFilter != 0 ||
                this.shapesFilter != 0 ||
                this.collectionFilter != 0
            }
        },
		data: () => ({
			isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			),
            priceRange:[],
            dialog: false,
            panel: [0],
            drawer:false,
		}),
        mounted(){
            this.priceRange = [this.prices.min, this.prices.max];
        },
        methods: {
            ...mapActions("shoppingCart", ["filter", ]),
            filterCollection(idCollection){
				this.$emit("changeFilter", true);
                this.filter({property:"collectionFilter",with:idCollection});
                this.dialog = false;
            },
            cleanCollection(){
                this.filter({property:"collectionFilter",with:0});
            },
            filterPrice(priceRange){
                this.filter({property:"priceFilter",with:priceRange});
            },
            cleanPrice(){
                this.priceRange = [this.prices.min, this.prices.max];
                this.filter({property:"priceFilter",with:[]});
            },
            close(){
                this.dialog = false;
            },
            filterSize(idSize){
                this.filter({property:"sizeFilter",with:idSize});
            },
            cleanSize(){
                this.filter({property:"sizeFilter",with:0});
            },
            
            filterData(id,filterName){
				this.$emit("changeFilter", true);
                this.filter({property:`${filterName}Filter`,with:id});
                this.dialog = false;
            },
            cleanFilter(filterName){
                this.filter({property:`${filterName}Filter`,with:0});
            },
            cleanAllFilters(){
                this.priceRange = [this.prices.min, this.prices.max];
                this.filter({property:"priceFilter",with:[]});
                this.filter({property:"sizeFilter",with:0});
                this.filter({property:"categoriesFilter",with:0});
                this.filter({property:"colorsFilter",with:0});
                this.filter({property:"stylesFilter",with:0});
                this.filter({property:"roomsFilter",with:0});
                this.filter({property:"themesFilter",with:0});
                this.filter({property:"shapesFilter",with:0});
                this.filter({property:"collectionFilter",with:0});

            },
        },
	}
</script>

<style scoped>
    a{
        text-decoration:none;
    }
    a:hover{
        text-decoration:underline;
    }
    p{
        padding:0px;
        margin:0px;
    }
    .active{
        font-weight:bold;
    }
    .v-expansion-panel-content >>> .v-expansion-panel-content__wrap{
        padding: 0 24px 16px !important;
    }
</style>
