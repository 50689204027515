<template>
	<v-container fluid style="padding: 0px;">
		<!-- <div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10" >
			<v-row class="mt-2 mb-6" justify="center" v-if="dataReady" style="width:100%;">
				<v-col cols="12" sm="12" md="6" lg="4" xl="3" v-for="(collection,index) in collections" :key="index">
					<artCard
						:image="getImage(collection.title)"
						:title="collection.title"
						:price="getPrice(collection.title)"
						:url="collection.url"
						:orientation="collection.orientation"
						:id="`${collection.id}`"
					>
					</artCard>
				</v-col>
			</v-row>
		</div> -->
		<div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10" >
			<div style="display:flex; justify-content:space-evenly; flex-flow:row wrap;" v-if="dataReady" class="mt-2 mb-6">
				<div v-for="(collection,index) in collections" :key="index" class="mx-2 my-2">
					<artCard
						:image="getImage(collection.title)"
						:title="collection.title"
						:price="getPrice(collection.title)"
						:url="collection.url"
						:orientation="collection.orientation"
						:id="`${collection.id}`"
						:history="getHistory(collection.title)"
					>
					</artCard>
				</div>
			</div>
		</div>
	</v-container>
</template>

<script>
import ArtCard from './ArtCard'
import { mapState } from "vuex";
	export default {
		name: 'ArtCardRow',
		components: { ArtCard },
        props: ['first', 'left', 'right', 'collections', 'collectionsLength', 'collectionFilter'],
		beforeDestroy(){
			document.removeEventListener('scroll', this.loadMore)
		},
		data: () => ({
			isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			),
			busy: false
		}),
        computed: {
            ...mapState("shoppingCart", ["artPieces", "images","size", "printedMedium", "frame", "liner", "artPiecesOk", "propertiesOk",  ]),
            dataReady(){
                return this.artPiecesOk && this.propertiesOk;
            }, 
            isSmallScreen(){
				var screenType = "Desktop";
				if(this.displaySizeClass == 'mobile-size')
					screenType = "Mobile";
				if(this.displaySizeClass == 'tablet-size')
					screenType = "Tablet";
				return screenType
			},
			displaySizeClass(){
				var displayClass = "desktop-size";
				var windowWidth = this.widthSize;
				if(windowWidth <= 640)
					displayClass = "mobile-size";
				if(windowWidth >= 1024 && windowWidth <= 1440)
					displayClass = "tablet-size";
				return displayClass
			},
			widthSize(){
				var windowWidth = window.innerWidth;
				if(windowWidth < 320)
					windowWidth = 320
				if(windowWidth > 640 && windowWidth < 1024)
					windowWidth = 640
				if(windowWidth > 1440 && windowWidth < 1920)
					windowWidth = 1440
				if(windowWidth > 1920)
					windowWidth = 1920
				return windowWidth
			},
			restHeight(){
				var size = this.isSmallScreen? window.innerHeight-362 :window.innerHeight-128-96-24-200;
				return size
			},
        },
		methods:{
			loadMore () {
				this.busy = true;
				this.$emit("scrollEnd", true);
				this.busy = false;
			},

			getImage(title){
				var artPiece = this.artPieces.find(x=>x.title.includes(title))
				var images = this.images.find(x=>x.idArtPiece == artPiece.id)
				return images.base64
			},
            getPrice(title){
				var artPiece = this.artPieces.find(x=>x.title.includes(title))
                var size =  this.size.find(x=>x.id == parseInt(artPiece.sizes.split("|")[0]))
                var printedMediumPrice =   this.printedMedium.find(x=>x.id == parseInt(artPiece.printedMediums.split("|")[0])).price
                var framePrice =  this.frame.find(x=>x.id == parseInt(artPiece.frames.split("|")[0])).price
                var linerPrice =  this.liner.find(x=>x.id == parseInt(artPiece.liners.split("|")[0])).price
                var widthXHeight = size!=null? size.width * size.height: 0
                var sizePrice = size!=null? size.price : 0
                return (artPiece.price + sizePrice*widthXHeight + printedMediumPrice*widthXHeight + framePrice*widthXHeight + linerPrice*widthXHeight);
            },
			getHistory(title){
				var artPiece = this.artPieces.find(x=>x.title.includes(title));
				return artPiece?.history;
			},
		},
	}
</script>

<style scoped>
.list-group {
	overflow-x: auto;
}
</style>