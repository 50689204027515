<template>
    <v-expansion-panel>
        <v-divider></v-divider>
        <v-expansion-panel-header  class="poppinsSemiboldFont" style="font-size:14px;">
            {{filterLabel[languageId]}}
            <v-btn v-if="filterData!=0" class="ml-4" color="error" icon x-small @click="cleanFilter" text max-width="25">
                <v-icon >mdi-close</v-icon>
            </v-btn>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <!-- <v-list dense style="width:100%; max-width:250px;"> -->
            <v-list dense >
                <v-list-item v-for="(data,index) in filterList" :key="index" link @click="filter(data.id)">
                    <v-list-item-content>
                        <v-list-item-title class="text-left v-expansion-panel-content poppinsRegularFont"  >
                            <a :class="{active:(data.id==filterData)}">{{data.name.split("|")[languageId]}}</a>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import { mapState } from "vuex";
	export default {
		name: 'FilterListData',
        props: ['filterLabel', 'filterList', 'filterData'],
        computed: {
            ...mapState("shoppingCart", [
                    "languageId",
                ]),
        },
		data: () => ({
			isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			),
		}),
        mounted(){
            // console.log(this.filterData)
        },
        methods: {
            filter(id){
				this.$emit("changeFilter", id);
            },
            cleanFilter(){
				this.$emit("cleanFilter");
            },
        },
	}
</script>

<style scoped>
    a{
        text-decoration:none;
    }
    a:hover{
        text-decoration:underline;
    }
    p{
        padding:0px;
        margin:0px;
    }
    .active{
        font-weight:bold;
    }
    .v-expansion-panel-content >>> .v-expansion-panel-content__wrap{
        padding: 0 24px 16px !important;
    }
</style>
