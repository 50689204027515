<template>
	<v-container :ref="`id-${id}`" :id="`id-${id}`">
        <v-row justify="center" >
            <v-hover v-slot="{hover}">
                <v-card
                    tile
                    width=310
                    height=330
                    min-width=220
                    min-height=250
                    flat
                > 
                    <v-img
                        class="rounded-lg"
                        :src="require(`../../assets/${image}`)"
                        :lazy-src="require(`../../assets/Mini-Pictures/${image.split('.')[0]}.jpg`)"
                        max-height="250"
                        max-width="310"
                        height="250"
                        width="310"
                        position="center"
                        :alt="history.includes('|')?history.split('|')[languageId]:history"
                    >
                        <template v-slot:placeholder>
                            <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                            >
                                <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                                ></v-progress-circular>
                            </v-row>
                        </template>
                        <div style="height:210px;" @click="open()"></div>
                        <div @click="addToCart()">
                            <v-btn v-if="hover || $vuetify.breakpoint.name=='xs'" style="color:white; text-transform: capitalize;" x-small color="rgb(0,0,0,0.5)" @click="addToCart()" class="poppinsRegularFont">{{detailsLabel[languageId]}}</v-btn>
                        </div>
                    </v-img>
                    <v-card-title class="mt-2" style="padding:0px;">
                        <v-row no-gutters>
                            <v-col style="text-align:left;" cols="10">
                                <h3 @click="open()" style="word-break: break-word; font-size:14px; line-height:20px;" class="poppinsExtraBoldFont">{{title.split("|")[languageId]}}</h3>
                                <p style="margin:0px; font-size:14px;" v-if="isNaN(price)" class="poppinsRegularFont"><span>{{comingSoonLabel[languageId]}}</span></p>
                                <p style="margin:0px; font-size:14px;" v-if="!isNaN(price)" class="poppinsRegularFont"><span>{{startingPriceLabel[languageId]}}</span>$ {{price==null?'N/A':Math.round(price).toLocaleString()}}</p>
                            </v-col>
                            <v-col style="text-align:right;" cols="2">
                                <div class="text-center d-flex align-center justify-space-around" v-if="parseInt(price)">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn icon class="mt-1" x-small color="primary" v-bind="attrs" v-on="on" @click="addToCart()"><v-icon >mdi-cart-plus</v-icon></v-btn>
                                        </template>
                                        <span>{{addToCartLabel[languageId]}}</span>
                                    </v-tooltip>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-title>

                    <!-- <v-card-text style="text-align:center;" v-if="hover || $vuetify.breakpoint.name=='xs'">
                        <div class="text-center d-flex align-center justify-space-around" v-if="parseInt(price)">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon class="mx-2" color="secondary" v-bind="attrs" v-on="on" @click="addToCart()"><v-icon >mdi-cart-plus</v-icon></v-btn>
                                </template>
                                <span>{{addToCartLabel[languageId]}}</span>
                            </v-tooltip>
                        </div>
                    </v-card-text> -->
                </v-card>
            
            </v-hover>
        </v-row>
        <artModal 
            v-if="dialog"
            @closed="close"
            @added="added"
            :dialog="dialog" 
            :image="image"
            :title="title"
            :url="url"
            :orientation="orientation"
            :history="history"
        ></artModal>

        <addedElementSnackbar :text="text" :snackbar="snackbar" @snackEnd="snackbar=false"></addedElementSnackbar>
	</v-container>
</template>

<script>
import artModal from './ArtModal';
import addedElementSnackbar from './AddedElementSnackbar';
import { mapState, mapActions } from "vuex";
	export default {
		name: 'ArtCard',
		components: { artModal, addedElementSnackbar },
        props: ['image', 'url', 'title', 'price', 'orientation', 'id', 'history'],
		data: () => ({
			isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			),
            dialog: false,
            snackbar:false,
            text: '',
		}),
        computed: {
            ...mapState("shoppingCart", [
                "languageId", "detailsLabel", "addToCartLabel", "startingPriceLabel", "comingSoonLabel", 
                "themesFilter", "themesNames","baseArtPiecesUrl",
            ]),
        },
        methods:{
            ...mapActions("shoppingCart", ["setMutation",]),
            details(){
                this.dialog = true;
            },
            addToCart(){
                this.dialog = true;
            },
            close(value){
                this.dialog = value;
            },
            added(value){
                this.dialog = false;
                this.text=value;
                this.snackbar = true;
            },
            open(){
				this.setMutation({with:this.id,property:'lastVisited'})
				let baseUrl =this.baseArtPiecesUrl[this.languageId];
				let themeUrl = this.themesNames.find(x=>x.id == this.themesFilter)?.name?.split("|")[this.languageId];
                this.$router.push(`/${baseUrl}/${themeUrl}/${this.title.split('|')[this.languageId]}`)
            }
        }
	}
</script>

<style scoped>
h3:hover{
    text-decoration:underline;
    cursor: pointer;
}
</style>
