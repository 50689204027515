<template>
	<v-container fluid style="padding:0px;" :class="displaySizeClass">
		<v-img
			v-if="isSmallScreen!='Mobile'"
			:src="require(`../assets/Galery/0Q3A5008-11-Pano_L.jpg`)"
			:lazy-src="require(`../assets/Galery/0Q3A5008-11-Pano_L_MINI.jpg`)"
			position="center"
			:contain="false"
			style="margin-top:-128px;"
			height="180"
		>
			<template v-slot:placeholder>
				<v-row class="fill-height ma-0" align="center" justify="center" >
					<v-progress-circular indeterminate color="grey lighten-5" ></v-progress-circular>
				</v-row>
			</template>
		</v-img>



		<div style="width:100%; height:128px; margin-top:-128px; background-color:gray;" v-if="isSmallScreen!='Mobile'"></div>
		
		<v-row no-gutters v-if="themesFilterMetaData != null" class="text-center">
			<v-col cols="12">
				<h1>{{themesFilterMetaData.name.split("|")[languageId]}}</h1>
				<p>{{themesFilterMetaData.description.split("|")[languageId]}}</p>
			</v-col>
		</v-row>
		
		<v-row no-gutters class="text-center mt-6" style="padding:0px; height:100%" >
			<v-col  cols="12" :md="isSmallScreen!='Mobile'?4:12" lg="3" xl="2" v-if="isSmallScreen != 'Mobile'" class="text-left">
                <filterByComponent :collectionNames="collectionNames" :prices="priceRange" @changeFilter="(val)=>{changeFilter()}" ></filterByComponent>
			</v-col>
			<v-col  cols="12" :md="isSmallScreen!='Mobile'?8:12" lg="9" xl="10">
				<v-row no-gutters>
					<v-tabs show-arrows v-model="selectedTab">
						<!-- <v-tabs-slider color="teal lighten-3"></v-tabs-slider> -->
						<a v-for="(name,index) in themesNames" :key="index" :href="redirectUrl(name.id)" style="text-decoration: none;">
							<v-tab style="width:160px;">
								{{name.name.split("|")[languageId]}}
							</v-tab>
						</a>
					</v-tabs>
				</v-row>
				<v-row no-gutters :style="isSmallScreen=='Mobile'?'position:sticky; top:56px; z-index:5; background-color:white; padding:2px 0px;':''">
					<v-col cols="6" >
						<div class="ml-5" style="text-align:start;"  v-if="isSmallScreen == 'Mobile'">
							<filterByComponent :collectionNames="collectionNames" :prices="priceRange" @changeFilter="(val)=>{this.ShowingPages=2}" ></filterByComponent>
						</div>
					</v-col>
					<v-col cols="6">
						<div :class="isSmallScreen == 'Mobile'?'mr-5':'mr-10'" style="text-align:end;">
							<v-menu offset-y >
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										color="primary"
										dark
										v-bind="attrs"
										v-on="on"
										class="poppinsRegularFont"
									>
										{{sortBy.split("|")[languageId]}}
									</v-btn>
								</template>
								<v-list>
									<v-list-item
										v-for="(item, index) in sortByList"
										:key="index"
									>
										<v-list-item-title @click="sortByClick(item)" :class="{active:(sortBy==item)}" class="poppinsRegularFont" style="font-size:14px;">{{ item.split("|")[languageId] }}</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
						</div>
					</v-col>
				</v-row>
				
                <artCardRow :collections="filteredArtPieces.slice(0,this.ShowingPages*this.showingRows)" :collectionsLength="filteredArtPieces.length" @scrollEnd="(val)=>loadMore(val)"></artCardRow>
				<v-btn v-if="endShowingPages" @click="loadMore()" text color="primary" class="poppinsRegularFont">{{more[languageId]}}</v-btn>
				<!-- <artModal dialog="true"></artModal> -->
			</v-col>
        </v-row>
	</v-container>
</template>

<script>
import artCardRow from './components/ArtCardRow';
import filterByComponent from './components/FilterByComponent';
import { mapState, mapActions } from "vuex";

	export default {
		name: 'ArtGalery',
		components: { artCardRow, filterByComponent},
		data: () => ({
			staticPageTitle:['Fotografías hechas cuadros por Karlus Morales','Photographs turned into paintings by Karlus Morales'],
			staticPageMetaTitle:['¡Descubre mis increíbles fotografías hechas cuadros! | Karlus Morales','Discover my amazing photographs turned into paintings! | Karlus Morales'],
			staticPageDescription:['En Karlus Morales, puedes encontrar una amplia selección de fotografías hechas cuadros para decorar tus espacios. Explora mis colecciones y encuentra la pieza perfecta para ti. ¡Compra ahora!','At Karlus Morales, you can find a wide selection of photographs turned into paintings to decorate your spaces. Explore my collections and find the perfect piece for you. Buy now!'],
			staticPageTags:['Venta de cuadros, fotografías artísticas, Karlus Morales, decoración de interiores ','Sale of paintings, artistic photographs, Karlus Morales, interior decoration'],
            metaImage:'',
			metaImageID:17,
			pageTitle:['',''],
			pageDescription:['',''],
			pageMetaTitle:['',''],
            pageKeywords:['',''],
			isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			),
			// sortBy:"Ordenar por|Sort by",
			// ShowingPages:2,
			more:["Más", "More"],
			selectedTab:0,
			requiresReload:false,
		}),
        computed: {
            ...mapState("shoppingCart", 
				[
					"collectionNames", "priceRange", "artPieces", "collectionFilter", "priceFilter", "sortByList", "languageId", "ShowingPages",
					"categoriesFilter","colorsFilter","stylesFilter","roomsFilter","themesFilter","shapesFilter","themesNames","baseArtPiecesUrl",
					"lastVisited", "filterLabel", "sortBy", "sizeFilter","images","artPiecesOk",
				]),
			endShowingPages(){
				var ShowingPages = this.ShowingPages;
				var showingRows = this.showingRows;
				var artPiecesLength = this.filteredArtPieces.length;
				return ShowingPages*showingRows <= artPiecesLength
			},
			showingRows(){
				var rows = 4;
				var windowWidth = window.innerWidth;
				if(windowWidth < 960)
					rows = 1;
				if(windowWidth >= 960 && windowWidth < 1264)
					rows = 2; 
				if(windowWidth >= 1264 && windowWidth < 1904)
					rows = 3
				return rows
			},
            isSmallScreen(){
				var screenType = "Desktop";
				if(this.displaySizeClass == 'mobile-size')
					screenType = "Mobile";
				if(this.displaySizeClass == 'tablet-size')
					screenType = "Tablet";
				return screenType
			},
			displaySizeClass(){
				var displayClass = "desktop-size";
				var windowWidth = this.widthSize;
				if(windowWidth <= 640)
					displayClass = "mobile-size";
				if(windowWidth >= 1024 && windowWidth <= 1440)
					displayClass = "tablet-size";
				return displayClass
			},
			widthSize(){
				var windowWidth = window.innerWidth;
				if(windowWidth < 320)
					windowWidth = 320
				if(windowWidth > 640 && windowWidth < 1024)
					windowWidth = 640
				if(windowWidth > 1440 && windowWidth < 1920)
					windowWidth = 1440
				if(windowWidth > 1920)
					windowWidth = 1920
				return windowWidth
			},
			filteredArtPieces(){
				var pieces = this.artPieces;
				pieces = pieces.sort((x,y)=>y.id-x.id);
				if(this.priceFilter.length > 0){
					pieces = pieces.filter(x=>x.price >= this.priceFilter[0] && x.price <= this.priceFilter[1]);
				}
				if(this.sizeFilter != ""){
					pieces = pieces.filter(x=>x.sizes.split("|").some((e)=>e==this.sizeFilter))
				}
				if(this.categoriesFilter != 0){
					pieces = pieces.filter(x=>x.idCategory == this.categoriesFilter)
				}
				if(this.colorsFilter != 0){
					pieces = pieces.filter(x=>x.idColor == this.colorsFilter)
				}
				if(this.stylesFilter != 0){
					pieces = pieces.filter(x=>x.idStyle == this.stylesFilter)
				}
				if(this.roomsFilter != 0){
					pieces = pieces.filter(x=>x.idRoom == this.roomsFilter)
				}
				if(this.themesFilter != 0){
					pieces = pieces.filter(x=>x.idTheme == this.themesFilter)
				}
				if(this.shapesFilter != 0){
					pieces = pieces.filter(x=>x.idShape == this.shapesFilter)
				}
				if(this.collectionFilter != ""){
					pieces = pieces.filter(x=>x.idCollection == this.collectionFilter)
				}

				if("Precio (bajo a alto)|Price (low to high)".includes(this.sortBy)){
					return pieces.sort((a,b)=> {
						let priceA = a.price==null?0:a.price;
						let priceB = b.price==null?0:b.price;
						return priceA - priceB
					})
				}
				else if("Precio (alto a bajo)|Price (high to low)".includes(this.sortBy)){
					return pieces.sort((a,b)=> {
						let priceA = a.price==null?0:a.price;
						let priceB = b.price==null?0:b.price;
						return priceB - priceA
					})
				}
				else if("Nombre (A a la Z)|Name (A to Z)".includes(this.sortBy)){
					return pieces.sort((a,b)=> a.title.toLowerCase().split("|")[this.languageId] > b.title.toLowerCase().split("|")[this.languageId]? 1: -1)
				}
				else if("Nombre (Z a la A)|Name (Z to A)".includes(this.sortBy)){
					return pieces.sort((a,b)=> b.title.toLowerCase().split("|")[this.languageId] > a.title.toLowerCase().split("|")[this.languageId]? 1: -1)
				}
				else{
					return pieces.sort((a,b)=> a.collection > b.collection? 1: -1)
				}
			},
			themesFilterMetaData(){
				if(this.themesFilter != 0){
					let theme = this.themesNames.find(x=>x.id == this.themesFilter);
					return theme;
				}
				return null;
			},
        },
		// watch:{
		// 	themesFilterMetaData(newTheme){
		// 		if(newTheme!=null){
		// 			this.pageTitle = newTheme.metaTitle.split("|");
		// 			this.pageDescription = newTheme.metaDescription.split("|");
		// 			this.pageKeywords = newTheme.metaTags.split("|")
		// 			this.pageMetaTitle = newTheme.metaTitle.split("|");
		// 		}
		// 		else{
		// 			this.pageTitle = this.staticPageTitle;
		// 			this.pageDescription = this.staticPageDescription;
		// 			this.pageKeywords = this.staticPageTags
		// 			this.pageMetaTitle = this.staticPageMetaTitle;
		// 		}
		// 	},
		// },
		metaInfo(){
			return{
				title: this.pageTitle[this.languageId],
				meta: [
					{
					vmid: 'robots',
					name: 'robots',
					content: 'index, follow',
					},
					{
					vmid: 'title',
					name: 'title',
					content: this.pageMetaTitle[this.languageId],
					},
					{
					vmid: 'description',
					name: 'description',
					content: this.pageDescription[this.languageId],
					},
					{
					vmid: 'keywords',
					name: 'keywords',
					content: this.pageKeywords[this.languageId],
					},
					{
					vmid: 'og:title',
					property: 'og:title',
					content: this.pageMetaTitle[this.languageId],
					},
					{
					vmid: 'og:description',
					property: 'og:description',
					content: this.pageDescription[this.languageId],
					},
					{
					vmid: 'og:image',
					property: 'og:image',
					content: this.metaImage,
					},
					{
					vmid: 'og:url',
					property: 'og:url',
					content: `${window.location.href}`,
					},
					{
					vmid: 'twitter:title',
					name: 'twitter:title',
					content: this.pageMetaTitle[this.languageId],
					},
					{
					vmid: 'twitter:description',
					name: 'twitter:description',
					content: this.pageDescription[this.languageId],
					},
					{
					vmid: 'twitter:image',
					name: 'twitter:image',
					content: this.metaImage,
					},
					{
					vmid: 'twitter:url',
					name: 'twitter:url',
					content: `${window.location.href}`,
					},
					{
					vmid: 'twitter:card',
					name: 'twitter:card',
					content: 'summary',
					},
				]
			}
		},
        methods: {
            ...mapActions("shoppingCart", ["setMutation", "filter" ]),
			sortByClick(item){
				this.setMutation({with:item,property:'sortBy'})
				// this.sortBy = item;
			},
			loadMore(){
				let ShowingPages = this.ShowingPages+1
				this.setMutation({with:ShowingPages,property:'ShowingPages'})
			},
			changeFilter(){
				this.setMutation({with:2,property:'ShowingPages'})
			},
			filterArtPiecesByThemeName(name){
				// if(name!="" && name!=undefined && name!=null){
				// 	let theme = this.themesNames.find(x=>x.name.toLowerCase().includes(name.toLowerCase()));
				// 	if(theme != null){
				// 		this.filter({property:"themesFilter",with:theme.id});
				// 		// if(theme.id != ""){
				// 			this.metaImage = this.selectMetaImage(theme.idImage2);
				// 		// }
				// 	}
				// 	//else {
				// 	// 	this.metaImage = this.selectMetaImage(this.metaImageID);
				// 	// }
				// 	this.selectedTab = this.themesNames.indexOf(theme);
				// }
				// else{
				// console.log("idImage")
				// 	this.filter({property:"themesFilter",with:0});
				// }
				name = (name!="" && name!=undefined && name!=null)?name:"todo"
				let theme = this.themesNames.find(x=>x.name.toLowerCase().includes(name.toLowerCase()));
				this.metaImage = this.selectMetaImage(theme.idImage2);
				this.filter({property:"themesFilter",with:theme?.id??0});
				this.selectedTab = this.themesNames.indexOf(theme);
			},
			redirectUrl(id){
				//if(this.$route.params.theme != this.themesNames.find(x=>x.id == id)?.name?.split("|")[this.languageId]){
					//this.filter({property:'themesFilter',with:id})
					let baseUrl = this.baseArtPiecesUrl[this.languageId];
					let themeUrl = this.themesNames.find(x=>x.id == id)?.name?.split("|")[this.languageId];
					//this.$router.push(`/${baseUrl}/${themeUrl}`)
					return `/${baseUrl}/${themeUrl}`
				//}
			},
			getImageById(idImage){
				if(this.images != null){
					let image = this.images.find(x=>x.id == idImage)
					return image.base64
				}
			},
            selectMetaImage(idImage){
				idImage = idImage!=null?idImage:this.metaImageID;
				let mImage = this.getImageById(idImage)
				if(mImage != null){
                    return window.location.origin + require(`../assets/${mImage}`)
				}
            },
			MetaDataNoFilter(){
				let newTheme = this.themesFilterMetaData
				if(newTheme!=null){
					this.pageTitle = newTheme.metaTitle.split("|");
					this.pageDescription = newTheme.metaDescription.split("|");
					this.pageKeywords = newTheme.metaTags.split("|")
					this.pageMetaTitle = newTheme.metaTitle.split("|");
				}
				else{
					this.pageTitle = this.staticPageTitle;
					this.pageDescription = this.staticPageDescription;
					this.pageKeywords = this.staticPageTags
					this.pageMetaTitle = this.staticPageMetaTitle;
				}
			},
			initialLoad(){
				this.filterArtPiecesByThemeName(this.$route.params.theme);
				this.MetaDataNoFilter();
				if(this.lastVisited){
					setTimeout(() => {
						const el = this.$el.querySelector(`#id-${this.lastVisited}`)
						el?.scrollIntoView({ behavior: "smooth", block: "end" });
						this.setMutation({with:'',property:'lastVisited'})
					}, 50);
				}
			}
        },
		mounted(){
			if(this.artPiecesOk){
				this.initialLoad();
			} else {
				this.requiresReload = true;
			}
		},
		updated(){
			if(this.requiresReload && this.artPiecesOk){
				this.initialLoad();
				this.requiresReload = false;
			}
		}
	}
</script>
<style scoped>
.v-list-item__title:hover{
	text-decoration:underline;
	cursor:pointer;
}
.active{
	font-weight:bold;
}
</style>